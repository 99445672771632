/* eslint-disable react-hooks/rules-of-hooks */
// Third-party
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Navigate } from 'react-router-dom';
import { useMemo } from 'react';
import classNames from 'classnames';

// App
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';
import { selectCompanySelected } from 'store/client/companySelected/selectors';
import { PAGES_ROUTES } from 'constants/routes';
import useDashboardStore from 'store/client/dashboard/useDashboardStore';
import {
  selectSelectedClient,
  selectSelectedMonth,
  selectSelectedYear,
} from 'store/client/dashboard/selectors';
import { useGetDashboardData } from 'store/server/company/queries';
import getMonthStartAndEndUtil from 'utils/getMonthStartAndEnd.util';
import { convertToBrazilianCurrencyFormat } from 'utils/currency.utils';
import useWindowSize from 'hooks/useWindowSize';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

interface ILastMonthReceiptsProps {
  toExport?: boolean;
}

function LastMonthReceipts({ toExport }: ILastMonthReceiptsProps) {
  const companySelected = useCompanySelectedStore(selectCompanySelected);
  const { width } = useWindowSize();

  if (!companySelected) {
    return <Navigate to={PAGES_ROUTES.authenticated.commonInitialRoute} replace />;
  }

  const selectedMonth = useDashboardStore(selectSelectedMonth);
  const selectedYear = useDashboardStore(selectSelectedYear);
  const selectedClient = useDashboardStore(selectSelectedClient);

  const { data: dashboardData } = useGetDashboardData(
    companySelected.id,
    {
      ...getMonthStartAndEndUtil(Number(selectedYear), Number(selectedMonth)),
      clientId: selectedClient?.id,
    },
    { requestIdEnabled: false }
  );

  const lastMonthReceiptsDataOrganized = useMemo(() => {
    return (
      dashboardData &&
      Object.values(
        Array.isArray(dashboardData?.salesBypendingTotal) &&
          dashboardData?.salesBypendingTotal?.reduce(
            (
              acc: Record<string, { client: string; payAmountPayPedingethod: number }>,
              saleByPendingTotal
            ) => {
              if (acc[saleByPendingTotal.client]) {
                acc[saleByPendingTotal.client].payAmountPayPedingethod += Number(
                  saleByPendingTotal.paymamountpaypedingethod
                );
              } else {
                acc[saleByPendingTotal.client] = {
                  client: saleByPendingTotal.client,
                  payAmountPayPedingethod: Number(saleByPendingTotal.paymamountpaypedingethod),
                };
              }

              return acc;
            },
            {}
          )
      )
    );
  }, [dashboardData]);

  const lastMonthReceiptsLabels = useMemo(
    () => lastMonthReceiptsDataOrganized?.map((saleByPendingTotal) => saleByPendingTotal.client),
    [lastMonthReceiptsDataOrganized]
  );

  const barsColor = useMemo(() => '#21a81f', []);
  const titleFontSize = useMemo(() => (toExport ? 24 : 20), [toExport]);
  const dataLabelRotation = useMemo(() => -20, []);

  const lastMonthReceiptsOptions = useMemo<ChartOptions<'bar'>>(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          bottom: 30,
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: false,
            text: 'Quantidade',
            align: 'center',
            color: '#000',
            font: {
              size: 20,
              weight: 'normal',
            },
          },
          ticks: {
            callback: function (value) {
              return convertToBrazilianCurrencyFormat(`${value}`);
            },
            color: '#000',
            font: {
              size: 14,
            },
          },
        },
        x: {
          display: false,
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.dataset.label || '';
              if (label) {
                label += ': ';
              }
              const value = context.parsed.y;
              label += convertToBrazilianCurrencyFormat(`${value}`);
              return label;
            },
          },
        },
        legend: {
          title: {
            display: true,
            position: 'start',
            font: {
              size: 18,
              weight: 'bold',
            },
            padding: {
              top: 80,
            },
          },
          align: 'center',
          position: 'bottom',
          labels: {
            color: '#000',
            font: {
              size: 14,
            },
          },
        },
        title: {
          display: true,
          text:
            width > 450
              ? ['Recebimentos - Meses Anteriores']
              : ['Recebimentos - Meses Anteriores'],
          font: {
            size: titleFontSize,
          },
          padding: {
            bottom: 40,
          },
        },
        datalabels: {
          anchor: 'start',
          color: 'rgb(0, 0, 0)',
          align: 'bottom',
          rotation: dataLabelRotation,
          font: {
            size: 16,
          },
          formatter: (value, context) => {
            const maxLegth = 20;

            if (
              lastMonthReceiptsLabels &&
              lastMonthReceiptsLabels?.[context.dataIndex]?.length > maxLegth
            ) {
              return lastMonthReceiptsLabels?.[context.dataIndex].substr(0, maxLegth) + '...';
            }

            return lastMonthReceiptsLabels?.[context.dataIndex];
          },
        },
      },
    }),
    [width, titleFontSize, dataLabelRotation, lastMonthReceiptsLabels]
  );

  const lastMonthReceiptsData = useMemo<ChartData<'bar'>>(
    () => ({
      labels: lastMonthReceiptsLabels,

      datasets: [
        {
          label: 'AR - A Receber',
          data: lastMonthReceiptsDataOrganized?.map((saleByMethodTotal) =>
            Number(saleByMethodTotal.payAmountPayPedingethod)
          ) as number[],
          backgroundColor: barsColor,
        },
      ],
    }),
    [barsColor, lastMonthReceiptsDataOrganized, lastMonthReceiptsLabels]
  );

  return (
    <div
      className={classNames(
        'graph w-full flex flex-col relative gap-x-[50px] bg-[#f6f9fd] rounded-2xl pt-[50px] pb-[10px] px-[10px] md:pl-[50px] md:pr-[80px]',
        {
          'flex-col gap-y-[80px] !px-[100px]': toExport,
          '!bg-white': toExport,
        }
      )}
    >
      <div className={classNames('relative', {})}>
        <Bar data={lastMonthReceiptsData} options={lastMonthReceiptsOptions} height={600} />
      </div>
    </div>
  );
}

export default LastMonthReceipts;
