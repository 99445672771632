/* eslint-disable import/no-named-as-default */
/* eslint-disable react-hooks/rules-of-hooks */
// Third-party
import { Navigate, useNavigate } from 'react-router-dom';
import { Key, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

//App
import { MoneyIcon, OpenEyeIcon } from 'assets/icons';
import LoadingListSkeleton from './components/LoadingListSkeleton';
import { PAGES_ROUTES } from 'constants/routes';
import { useGetUser } from 'store/server/user/queries';
import { useExportReports, useGetCompanyExpenseList } from 'store/server/company/queries';
import {
  ExpenseStatusCompareEnum,
  ExpenseStatusEnum,
  IExpense,
  IExpenseListOrderAttribute,
} from 'interfaces/companyTypes';
import { DateType, DateValueType } from 'react-tailwindcss-datepicker';
import usePersistedState from 'hooks/usePersistedState';
import { selectCompanySelected } from 'store/client/companySelected/selectors';
import { convertToBrazilianCurrencyFormat, toFloat } from 'utils/currency.utils';
import useCompanySelectedStore from 'store/client/companySelected/useCompanySelectedStore';
import ExpenseDetailsModal from 'components/ExpenseDetailsModal';
import DeleteIconTable from 'assets/icons/DeleteTable.icon';
import ToolTip from 'components/ToolTip';
import WriteOffModal from 'components/WriteOffModal';
import PageHeader from 'components/PageHeader/components/PageHeader';
import TableContainer from 'components/TableContainer';
import NavigationSelector from 'components/NavigationSelector';
import ExcludeExpenseModal from 'components/ExcludeExpenseModal';
import useNotificationStore from 'store/client/notification/useNotificationStore';
import { selectAddNotification } from 'store/client/notification/selectors';
import { exportPdf } from 'utils/pdf.utils';
import { dateUtils } from 'utils/formatDate';
import useFilterStates from 'hooks/useFilterStates';
import getMonthStartAndEndUtil from 'utils/getMonthStartAndEnd.util';
import { PAGINATION } from 'constants/clientParams';
import { exportExcel } from 'utils/excelExport.utils';
import { screenConfig } from 'utils/screenConfig.util';
import TableHeaderCell from 'components/TableHeaderCell';
import CustomTableCell from 'components/TableCell';
import { ORDER_ATTRIBUTTES, ascDescType } from 'constants/orderAttributtes';
import TransactionsIcon from 'assets/icons/Transactions.icon';
import TransactionsFilterBar from 'components/TransactionsFilterBar';
import { IColumnConfig } from 'interfaces/tableTypes';
import dayjs from 'dayjs';

const { ASC, DESC } = ORDER_ATTRIBUTTES;
const { DATE_DISPLAY_FORMAT, DATE_TIME_DISPLAY_FORMAT } = dateUtils.constants;
const { MEDIUM_SCREEN_SIZE } = screenConfig.constants;
// dayjs.extend(utc);
// dayjs.extend(timezone);
// dayjs.tz.setDefault('America/Sao_Paulo');

const TOTAL_VALUE_DISPLAY_COLOR = '#EF4444';

function List() {
  /*
  **** Component organization ****

   └── Declaration of generic hooks (e.g., useNavigate)
   └── State declaration
   └── Side effects (e.g., useEffect)
   └── Memoization (e.g., useMemo)
   └── Handlers (e.g., useCallback)
   └── JSX
   */

  // └── Declaration of generic hooks (e.g., useNavigate)
  const navigate = useNavigate();
  const tableContainerRef = useRef<HTMLTableElement>(null);

  // Selected table row
  const [selectedRowId, setSelectedRowId] = useState<null | number>(null);

  // └── State declaration
  const { data: user } = useGetUser();
  const companySelected = useCompanySelectedStore(selectCompanySelected);
  if (!companySelected)
    return (
      <Navigate to={user?.initialRoute ?? PAGES_ROUTES.authenticated.adminInitialRoute} replace />
    );

  // Pagination states
  const [page, setPage] = usePersistedState<number>('expensesList.page', PAGINATION.START_PAGE_NUM);
  const [pageSize, setPageSize] = usePersistedState<number>('expensesList.pageSize', 10);
  const [detailsExpenseModal, setDetailsExpenseModal] = useState<{
    display: boolean;
    expense: IExpense | null;
  }>({
    display: false,
    expense: null,
  });
  const [removeExpenseModal, setRemoveExpenseModal] = useState<{
    display: boolean;
    expense: IExpense | null;
    customConfirmationMessage?: string;
  }>({
    display: false,
    expense: null,
  });
  const [writeOffExpenseModal, setWriteOffExpenseModal] = useState<{
    display: boolean;
    expense: IExpense | null;
    customConfirmationMessage?: string;
  }>({
    display: false,
    expense: null,
  });

  // Sort states
  const [orderAttribute, setOrderAttribute] = useState<IExpenseListOrderAttribute>('dateEvent');
  const [order, setOrder] = useState<ascDescType>(ASC);

  // └── State declaration
  const firstDayOfMonth = useMemo(() => getMonthStartAndEndUtil().initialDate, []);
  const lastDayOfMonth = useMemo(() => getMonthStartAndEndUtil().finalDate, []);

  const [loadingReportExports, setLoadingReportExports] = useState<boolean>(false);
  const [loadingPdfExports, setLoadingPdfExports] = useState<boolean>(false);
  const addNotification = useNotificationStore(selectAddNotification);

  const {
    tempFilterState,
    submittedFilterState,
    hasFilterStatesToSubmit,
    hasFilterStatesSubmitted,
    changeTempState,
    submitStates,
    cleanStates,
  } = useFilterStates<{
    search: string | null;
    initialDate: DateType;
    endDate: DateType;
  }>({
    filtersStateKey: 'expensesListFilter',
    initalState: {
      search: null,
      initialDate: firstDayOfMonth,
      endDate: lastDayOfMonth,
    },
  });

  const {
    data: expenseListData,
    isLoading: expenseListIsLoading,
    isFetching: expenseListIsFetching,
  } = useGetCompanyExpenseList({
    start: page,
    size: pageSize,
    field: orderAttribute,
    order,
    description: submittedFilterState.search,
    companyId: companySelected?.id,
    dateInit: submittedFilterState.initialDate,
    dateFinal: submittedFilterState.endDate,
  });

  const { refetch: excelRefetch, isRefetching: isExcelRefetching } = useExportReports({
    size: pageSize,
    start: page * pageSize,
    filter: submittedFilterState?.search,
    initialDate: submittedFilterState?.initialDate,
    endDate: submittedFilterState?.endDate,
    companyId: companySelected.id,
    report: 'expense',
    type: 'xls',
  });

  const { refetch: pdfRefetch, isRefetching: isPDFRefetching } = useExportReports({
    size: pageSize,
    start: page * pageSize,
    filter: submittedFilterState?.search,
    initialDate: submittedFilterState?.initialDate,
    endDate: submittedFilterState?.endDate,
    companyId: companySelected.id,
    report: 'expense',
    type: 'pdf',
  });

  // └── Side effects (e.g., useEffect)
  // └── Handlers (e.g., useCallback)

  const handleSortByColumn = useCallback(
    (columnName: IExpenseListOrderAttribute | null, orderSelected?: ascDescType) => () => {
      if (!columnName) return;

      if (orderAttribute !== columnName) {
        setOrder(ASC); // If the sort field is different from the clicked column, set the order to ASC
      } else {
        setOrder(orderSelected ? orderSelected : order === ASC ? DESC : ASC); // If the sort field is the same as the clicked column, toggle between ASC and 'desc' order
      }
      setOrderAttribute(columnName); // Set the sort field to the clicked column
    },
    [orderAttribute, order]
  );

  const checkIfShowCards = useCallback(() => {
    if (window.innerWidth < MEDIUM_SCREEN_SIZE) {
      orderAttribute !== 'dateEvent' && setOrderAttribute('dateEvent');
      order !== ASC && setOrder(ASC);
    }
  }, [order, orderAttribute]);

  useEffect(() => {
    window.removeEventListener('resize', checkIfShowCards);
    window.addEventListener('resize', checkIfShowCards);

    return () => {
      window.removeEventListener('resize', checkIfShowCards);
    };
  }, [checkIfShowCards]);

  const showSkeletonLoading = useMemo(
    () => (!expenseListData?.result.data && expenseListIsLoading) || expenseListIsLoading,
    [expenseListIsLoading, expenseListData]
  );

  const handleClearFilters = useCallback(() => {
    cleanStates();
    setPage(PAGINATION.START_PAGE_NUM);
  }, [cleanStates, setPage]);

  const handleCloseModal = () => {
    setRemoveExpenseModal({ display: false, expense: null });
  };

  const handleExportReportsExcel = useCallback(() => {
    exportExcel({
      refetch: excelRefetch,
      setLoading: setLoadingReportExports,
      addNotification,
      filenamePrefix: 'lista_de_despesas',
    });
  }, [excelRefetch, setLoadingReportExports, addNotification]);

  const handleChangeSearchFilter = useCallback(
    async (event: { target: { value: string } }) => {
      changeTempState('search', event.target.value);
    },
    [changeTempState]
  );

  const handleChangeDateFilter = useCallback(
    (value: DateValueType) => {
      changeTempState('initialDate', value?.startDate || firstDayOfMonth);
      changeTempState('endDate', value?.endDate || lastDayOfMonth);
    },
    [changeTempState, firstDayOfMonth, lastDayOfMonth]
  );

  const handleClickSubmitFilters = useCallback(() => {
    if (hasFilterStatesToSubmit) {
      setPage(PAGINATION.START_PAGE_NUM);
      submitStates();
    }
  }, [hasFilterStatesToSubmit, setPage, submitStates]);

  const headerCells: { title: string; keyString?: IExpenseListOrderAttribute }[] = [
    { title: 'Data Despesa', keyString: 'dateEvent' },
    { title: 'Valor Despesa', keyString: 'amount' },
    { title: 'Valor Pago', keyString: 'amountPay' },
    { title: 'Valor Pendente', keyString: 'amountPending' },
    { title: 'Data da Baixa', keyString: 'receivePay' },
    { title: 'Descrição', keyString: 'description' },
    { title: 'Condição de Pagamento', keyString: 'paymethod' },
    // { title: 'Forma de Pagamento', keyString: 'paytype' },
    { title: 'Categoria', keyString: 'category' },
    { title: 'Status', keyString: 'status' },
    { title: 'Ações' },
  ];

  const columnConfig: IColumnConfig<IExpense>[] = [
    {
      key: 'dateEvent',
      render: (expense) =>
        expense.dateEvent ? dayjs.utc(expense.dateEvent).format(DATE_TIME_DISPLAY_FORMAT) : 'N/A',
      renderMobile: (expense) => (
        <p>
          Data Despesa: {expense.dateEvent ? dayjs(expense.dateEvent).format('DD/MM/YYYY') : 'N/A'}
        </p>
      ),
    },
    {
      key: 'amount',
      render: (expense) => (
        <span 
        className={classNames(
          'text-red-500'
        )}
        >
          <b>{convertToBrazilianCurrencyFormat(expense.amount)}</b>
        </span>
      ),
      renderMobile: (expense) => <p>Valor: {convertToBrazilianCurrencyFormat(expense.amount)}</p>,
    },

    {
      key: 'amountPay',
      render: (expense) =>
        (
          <span>
            <b>{convertToBrazilianCurrencyFormat( toFloat(expense.amountPay).toString())}</b>
          </span>
        ) ,
      renderMobile: (expense) => <p>Valor: {convertToBrazilianCurrencyFormat(toFloat(expense.amountPay).toString())}</p>,
    },

    {
      key: 'amountPending',
      render: (expense) =>(
        <span 
        className={classNames(
          'text-orange-500'
        )}
        >
          <b>{convertToBrazilianCurrencyFormat((toFloat(expense.amount) - toFloat(expense.amountPay)).toString())}</b>
        </span>
      ),
         
      renderMobile: (expense) => <p>Valor: {convertToBrazilianCurrencyFormat((toFloat(expense.amount) - toFloat(expense.amountPay)).toString())}</p>,
    },
    
    {
      key: 'receivePay',
      render: (expense) =>
        expense.receivePay ? dayjs.utc(expense.receivePay).format(DATE_TIME_DISPLAY_FORMAT) : 'N/A',
      renderMobile: (expense) => (
        <p>
          Data de Pagamento:{' '}
          {expense.receivePay ? dayjs(expense.receivePay).format('DD/MM/YYYY') : 'N/A'}
        </p>
      ),
    },
    {
      key: 'description',
      render: (expense) =>
        expense.description?.length > 20 ? (
          <>
            {expense.description.slice(0, 40)}
            <ToolTip tooltip={expense.description}>...</ToolTip>
          </>
        ) : (
          expense.description
        ),
      renderMobile: (expense) => <p>Descrição: {expense.description || 'N/A'}</p>,
    },
    {
      key: 'paymethod',
      render: (expense) => expense.paymethod,
      renderMobile: (expense) => <p>Pagamento: {expense.paymethod || 'N/A'}</p>,
    },
    // {
    //   key: 'paytype',
    //   render: (expense) => expense.paytype,
    //   renderMobile: (expense) => <p>Forma de Pagamento: {expense.paytype || 'N/A'}</p>,
    // },
    {
      key: 'category',
      render: (expense) => expense.category,
      renderMobile: (expense) => <p>Categoria: {expense.category}</p>,
    },
    {
      key: 'status',
      render: (expense) => (
        <span
          className={classNames(
            'rounded-[10px] px-[10px] py-[5px] items-center justify-center self-center',
            {
              'bg-green-100': expense.status === ExpenseStatusCompareEnum.PAID,
              'bg-red-100': expense.status === ExpenseStatusCompareEnum.PENDING,
              'bg-yellow-100 text-yellow-800': expense.status === ExpenseStatusCompareEnum.PARTIAL,
            }
          )}
        >
          {ExpenseStatusEnum[expense.status] || 'Status não descrito'}
        </span>
      ),
      renderMobile: (expense) => (
        <p>Status: {ExpenseStatusEnum[expense.status] || 'Status não descrito'}</p>
      ),
    },
    {
      key: 'actions',
      render: (expense) => (
        <>
          {expense.status !== ExpenseStatusCompareEnum.PAID && (
            <button onClick={() => setWriteOffExpenseModal({ display: true, expense })}>
              <MoneyIcon className="color-secondary group-enabled:hover:text-secondary" />
            </button>
          )}
          <button onClick={() => setDetailsExpenseModal({ display: true, expense })}>
            <OpenEyeIcon />
          </button>

          <button
            onClick={() =>
              setRemoveExpenseModal({
                display: true,
                expense,
              })
            }
          >
            <DeleteIconTable className="group-enabled:hover:text-secondary" />
          </button>
        </>
      ),
      renderMobile: (expense) => (
        <div className="flex gap-x-2">
          {expense.status !== ExpenseStatusCompareEnum.PAID && (
            <button onClick={() => setWriteOffExpenseModal({ display: true, expense })}>
              <MoneyIcon />
            </button>
          )}
          <button onClick={() => setDetailsExpenseModal({ display: true, expense })}>
            <OpenEyeIcon />
          </button>
          <button onClick={() => setRemoveExpenseModal({ display: true, expense })}>
            <DeleteIconTable />
          </button>
        </div>
      ),
    },
  ];

  const handleExportReportsPdf = useCallback(async () => {
    exportPdf({
      refetch: pdfRefetch,
      setLoading: setLoadingPdfExports,
      addNotification,
      fileName: `ECVFinancy_lista_de_despesas_`,
      company: {
        name: companySelected.name,
        doc: companySelected.cnpj,
      },
      docTitle: 'Relatório Lista de Despesas',
      pdfPeriod: `${dayjs(submittedFilterState.initialDate).format(DATE_DISPLAY_FORMAT)} - ${dayjs(
        submittedFilterState.endDate
      ).format(DATE_DISPLAY_FORMAT)}`,
      page: 'expenseList',
    });
  }, [
    pdfRefetch,
    setLoadingPdfExports,
    addNotification,
    submittedFilterState.initialDate,
    submittedFilterState.endDate,
  ]);

  const handleRowClick = (clientId: number) => {
    setSelectedRowId(clientId === selectedRowId ? null : clientId);
  };

  const pageHeaderProps = {
    iconTextTitleProps: {
      iconProps: {
        iconJSX: <TransactionsIcon className="w-[45px] h-[45px] !text-secondary" />,
      },
      textProps: {
        content: 'Lista de Despesas',
      },
    },
    totalValueDisplayProps: [
      {
        displayProps: {
          isLoadingData: expenseListIsFetching,
          skeletonProps: {
            useSkeleton: true,
          },
          displayedValueProps: {
            valueToDisplay: expenseListData?.totalPeriod,
            useCurrencyMask: true,
            textColor: TOTAL_VALUE_DISPLAY_COLOR,
          },
        },
        textProps: {
          title: 'Total do período selecionado',
        },
      },
      {
        displayProps: {
          isLoadingData: expenseListIsFetching,
          skeletonProps: {
            useSkeleton: true,
          },
          displayedValueProps: {
            valueToDisplay: expenseListData?.totalMonth,
            useCurrencyMask: true,
            textColor: TOTAL_VALUE_DISPLAY_COLOR,
          },
        },
        textProps: {
          title: 'Total do mês',
        },
      },
    ],
  };

  const transactionFilterBarProps = {
    showSkeletonLoading: showSkeletonLoading,
    isFetching: expenseListIsFetching,
    isLoading: expenseListIsLoading,
    tempFilterState: tempFilterState,
    isExportingExcel: loadingReportExports,
    isExportingPDF: loadingPdfExports,
    handleChangeSearchFilter: handleChangeSearchFilter,
    handleChangeDateFilter: handleChangeDateFilter,
    handleClearFilters: handleClearFilters,
    handleExportReportsExcel: handleExportReportsExcel,
    handleExportReportsPdf: handleExportReportsPdf,
    hasFilterStatesSubmitted: hasFilterStatesSubmitted,
    hasFilterStatesToSubmit: hasFilterStatesToSubmit,
    handleClickSubmitFilters: handleClickSubmitFilters,
    redirectToNewRegister: PAGES_ROUTES.authenticated.transactions.expenses.create,
  };

  const navigationSelectorProps = {
    totalResults: expenseListData?.result?.total || 0,
    pageSize: pageSize,
    currentPage: page,
    onChangePageSize: setPageSize,
    onPageChange: setPage,
    isLoading: expenseListIsLoading || expenseListIsFetching,
    resetSelection: setSelectedRowId,
  };

  return (
    <>
      {detailsExpenseModal.display && detailsExpenseModal.expense !== null && (
        <ExpenseDetailsModal
          setOpenModal={setDetailsExpenseModal}
          expenseData={detailsExpenseModal}
        />
      )}
      {writeOffExpenseModal.display && writeOffExpenseModal.expense !== null && (
        <WriteOffModal setOpenModal={setWriteOffExpenseModal} openModal={writeOffExpenseModal} />
      )}
      {removeExpenseModal.display && removeExpenseModal.expense?.id && (
        <ExcludeExpenseModal
          title="Remover Despesa"
          expenseId={removeExpenseModal.expense?.id}
          description={`Você tem certeza que deseja excluir esta despesa?`}
          onClose={handleCloseModal}
          useInlineData={true}
          inlineData={[
            {
              name: 'Data',
              value: removeExpenseModal.expense?.dateEvent,
              formatter: (e) => (e ? dayjs(e).format(DATE_TIME_DISPLAY_FORMAT) : ''),
            },
            {
              name: 'Valor',
              value: removeExpenseModal.expense?.amount,
              formatter: (value) => convertToBrazilianCurrencyFormat(`${value}`),
            },
            {
              name: 'Descrição',
              value: removeExpenseModal.expense?.description,
            },
            {
              name: 'Condição de Pagamento',
              value: removeExpenseModal.expense?.paymethod,
            },
            {
              name: 'Categoria',
              value: removeExpenseModal.expense?.category,
            },
          ]}
        />
      )}
      <div className="h-full">
        <div className="overflow-hidden min-h-[1600px] md:min-h-[1200px] lg:min-h-[1000px] xl:min-h-[800px]">
          <div className="pt-5 md:pt-[42px] p-[27px] md:p-8 w-full min-h-[calc(100vh-64px)] md:min-h-[calc(100vh-71px)]">
            {/* HEADER */}
            <PageHeader {...pageHeaderProps} />
            <TransactionsFilterBar {...transactionFilterBarProps} />
            {showSkeletonLoading ? (
              <LoadingListSkeleton />
            ) : expenseListData?.result?.data && expenseListData?.result?.data?.length <= 0 ? (
              <p className="w-full text-center text-[#595959] font-semibold">
                Nenhuma despesa encontrada!
              </p>
            ) : (
              <TableContainer>
                {/* Desktop */}
                <table
                  ref={tableContainerRef}
                  className="w-full text-sm text-left text-gray-500 hidden md:table table-data"
                >
                  <thead className="font-inter text-[10px] text-primary font-bold uppercase bg-gray-50 w-full">
                    <tr>
                      {headerCells.map(({ title, keyString }, index) => (
                        <TableHeaderCell<IExpenseListOrderAttribute>
                          key={index}
                          keyIndex={index}
                          order={order}
                          orderAttribute={orderAttribute}
                          handleSortByColumn={handleSortByColumn}
                          title={title}
                          keyString={keyString ?? null}
                        />
                      ))}
                    </tr>
                  </thead>
                  <tbody className="w-full">
                    {expenseListData?.result?.data.map((expense, expenseIndex) => (
                      <tr
                        className={classNames(
                          'bg-white border-b w-full h-[60px] text-xs text-black',
                          {
                            '!bg-white hover:!bg-gray-100': selectedRowId !== expense.id,
                            '!bg-gray-300': selectedRowId === expense.id,
                          }
                        )}
                        key={`expense-${expense.id}-row-${expenseIndex}`}
                        onClick={() => handleRowClick(expense.id)}
                      >
                        {columnConfig.map((col: { key: Key; render: (arg0: IExpense) => any }) => (
                          <CustomTableCell<IExpense>
                            key={`${col.key}`}
                            keyIndex={`${col.key}`}
                            content={expense}
                            renderContent={() => col.render(expense)}
                            className={`px-6 py-4 whitespace-nowrap ${
                              col.key === 'actions' ? 'flex flex-row gap-x-1 justify-between' : ''
                            }`}
                          />
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* Mobile */}
                <div className="flex flex-col gap-y-[18px] md:hidden w-full">
                  {expenseListData?.result?.data.map((expense, expenseIndex) => (
                    <div
                      className="bg-white px-[20px] py-[16px] overflow-hidden shadow-container rounded-[5px] w-full"
                      key={`company-card-${expense.id}-row-${expenseIndex}-mobile`}
                    >
                      <div className="w-full flex flex-col gap-y-[10px]">
                        <div className="flex flex-row justify-between">
                          <p
                            className={classNames(
                              'w-fit h-fit rounded-[10px] px-[10px] my-[5px] pb-[2px] text-[10px]',
                              {
                                'bg-green-100 text-green-800 font-medium':expense.status === 'PAID',
                                'bg-yellow-100 text-yellow-800 font-medium': expense.status === 'PARTIAL',
                                'bg-red-100 text-red-800 font-medium': expense.status === 'PENDING',
                              }
                            )}
                          >
                            {ExpenseStatusEnum[expense.status as keyof typeof ExpenseStatusEnum] ||
                              'Status não descrito'}
                          </p>
                          <div className="flex flex-row gap-x-2.5 h-fit">
                            <button
                              onClick={() =>
                                setDetailsExpenseModal({ display: true, expense: expense })
                              }
                              className={classNames({
                                group: true,
                              })}
                            >
                              <OpenEyeIcon className="group-enabled:hover:text-secondary" />
                            </button>
                            {expense?.status !== ExpenseStatusCompareEnum.PAID && (
                              <button
                                onClick={() =>
                                  setWriteOffExpenseModal({
                                    display: true,
                                    expense: expense,
                                  })
                                }
                                className={classNames({
                                  group: true,
                                })}
                              >
                                <MoneyIcon className="color-secondary group-enabled:hover:text-secondary" />
                              </button>
                            )}
                            <button
                              onClick={() =>
                                setRemoveExpenseModal({
                                  display: true,
                                  expense: expense,
                                  customConfirmationMessage: `Você tem certeza que deseja excluir a despesa: ${
                                    expense.description
                                  }. No dia: ${dayjs
                                    .utc(expense.dateEvent)
                                    .format(
                                      DATE_DISPLAY_FORMAT
                                    )}. No valor de: ${convertToBrazilianCurrencyFormat(
                                    expense.amount
                                  )}?`,
                                })
                              }
                              className={classNames({
                                group: true,
                              })}
                            >
                              <DeleteIconTable className="group-enabled:hover:text-secondary text-primary" />
                            </button>
                          </div>
                        </div>
                        <div className="flex flex-row w-full font-inter font-medium text-gray-500 text-xs/[20px]">
                          <span className="uppercase w-full">
                            <p>
                              <b className="text-primary">Data Despesa: </b>
                              {expense.dateEvent &&
                                dayjs.utc(expense.dateEvent).format(DATE_TIME_DISPLAY_FORMAT)}
                            </p>
                            <p>
                              <b className="text-primary">Data de Pagamento: </b>
                              {expense.receivePay &&
                                dayjs(expense.receivePay).format(DATE_TIME_DISPLAY_FORMAT)}
                            </p>
                            <p>
                              <b className="text-primary ">Valor: </b>{' '}
                              {convertToBrazilianCurrencyFormat(expense.amount ?? '-')}
                            </p>

                            <p>
                              <b className="text-primary">Descrição: </b>
                              {expense.description}
                            </p>
                            <p>
                              <b className="text-primary">Condição de Pagamento: </b>
                              {expense.paymethod ?? '-'}
                            </p>
                            <p>
                              <b className="text-primary">Categoria: </b>
                              <span className={classNames('text-xs')}>{expense.category}</span>
                            </p>
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </TableContainer>
            )}
            {!showSkeletonLoading && <NavigationSelector {...navigationSelectorProps} />}
          </div>
        </div>
      </div>
    </>
  );
}

export default List;
