// Third-party
import Skeleton from 'react-loading-skeleton';

// App
import { IGetSummaryData } from 'interfaces/summaryType';
import { convertToBrazilianCurrencyFormat } from 'utils/currency.utils';

import './style.css';
import classNames from 'classnames';
import { useMemo } from 'react';

interface IHeaderProps {
  isLoading: boolean;
  summaryData: IGetSummaryData | undefined;
}

function Header({ isLoading, summaryData }: IHeaderProps) {
  const monthProfitColors = useMemo(() => {
    if ((summaryData?.resume.balanceToReceve ?? 0) > 0) {
      return {
        bg: 'bg-[#cdfed4]',
        text: '!text-[#049b18]',
      };
    }

    return {
      bg: 'bg-[#ffcccc]',
      text: '!text-[#ff0000]',
    };
  }, [summaryData?.resume.balanceToReceve]);

  let averageSalesPerDay = Number(summaryData?.averageSalesPerDay);
  if (isNaN(Number(averageSalesPerDay))) {
    averageSalesPerDay = 0;
  }

  let profitPerSales = Number(summaryData?.resume.profitPerSales);
  if (isNaN(Number(profitPerSales)) || Number(profitPerSales) === Infinity) {
    profitPerSales = 0;
  }

  let profitPerSalesTotal =
    Number(summaryData?.resume.salesAverage) - Number(summaryData?.costSalesByExpense);

  return (
    <>
      <div className="summary-page-header-container !mt-[30px]">
        <h1 className="expense-sale-page-total-text bg-[#f6f9fd] !m-0 p-[30px] rounded-md">
          <span className="text-lg">Média de Venda</span>
          {isLoading ? (
            <Skeleton
              height={40.5}
              className="inline-block w-full"
              containerClassName="inline-block w-full"
            />
          ) : (
            <span className="text-xl text-secondary">
              {' '}
              {convertToBrazilianCurrencyFormat(`${summaryData?.salesAverage ?? 0}`)}
            </span>
          )}
        </h1>
        <h1 className="expense-sale-page-total-text bg-[#f6f9fd] !m-0 p-[30px] rounded-md">
          <span className="text-lg">Custo das Vendas pela Despesa</span>
          {isLoading ? (
            <Skeleton
              height={40.5}
              className="inline-block w-full"
              containerClassName="inline-block w-full"
            />
          ) : (
            <span className="text-xl text-secondary !text-[#ff0000]">
              {' '}
              {convertToBrazilianCurrencyFormat(`${summaryData?.costSalesByExpense ?? 0}`)}
            </span>
          )}
        </h1>
        <h1 className="expense-sale-page-total-text bg-[#f6f9fd] !m-0 p-[30px] rounded-md">
          <span className="text-lg">Lucro por Vendas</span>
          {isLoading ? (
            <Skeleton
              height={40.5}
              className="inline-block w-full"
              containerClassName="inline-block w-full"
            />
          ) : (
            <span 
              className={`text-xl text-secondary ${
              (profitPerSalesTotal ?? 0) < 0 ? '!text-[#ff0000]' : ''}`}
            >
              {convertToBrazilianCurrencyFormat(`${profitPerSalesTotal ?? 0}`)}
            </span>
          )}
        </h1>
        <h1 className="expense-sale-page-total-text bg-[#f6f9fd] !m-0 p-[30px] rounded-md">
          <span className="text-lg">Média Serviços por Dia</span>
          {isLoading ? (
            <Skeleton
              height={40.5}
              className="inline-block w-full"
              containerClassName="inline-block w-full"
            />
          ) : (
            <span className="text-xl text-secondary">
              {' '}
              {`${
                Number(averageSalesPerDay).toLocaleString('pt-BR', {
                  useGrouping: false,
                }) ?? 0
              }`}
            </span>
          )}
        </h1>
      </div>

      <div className="summary-page-header-container !mt-0 !mb-[30px]">
        <h1
          className={classNames(
            'expense-sale-page-total-text !m-0 p-[30px] rounded-md',
            isLoading ? 'bg-[#f6f9fd]' : monthProfitColors.bg
          )}
        >
          <span className="text-lg">Porcentagem do lucro total</span>
          {isLoading ? (
            <Skeleton
              height={40.5}
              className="inline-block w-full"
              containerClassName="inline-block w-full"
            />
          ) : (
            <span className={`text-xl text-secondary ${
              (summaryData?.resume.balanceToReceve ?? 0) < 0 ? '!text-[#ff0000]' : ''}`}
            
            >
              {(summaryData?.resume.balanceToReceve ?? 0) < 0 && '-'}
              {(summaryData?.resume.balanceToReceve ?? 0) > 0 && '+'}
              {Number(profitPerSales).toLocaleString('pt-BR', {
                useGrouping: false,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
              %
            </span>
          )}
        </h1>
        <h1
          className={classNames(
            'expense-sale-page-total-text !m-0 p-[30px] rounded-md',
            isLoading ? 'bg-[#f6f9fd]' : monthProfitColors.bg
          )}
        >
          <span className="text-lg">Lucro do valor recebido do mês</span>
          {isLoading ? (
            <Skeleton
              height={40.5}
              className="inline-block w-full"
              containerClassName="inline-block w-full"
            />
          ) : (
            <span className={classNames('text-xl text-secondary', monthProfitColors.text)}>
              {(summaryData?.resume.balanceToReceve ?? 0) > 0 && '+'}
              {convertToBrazilianCurrencyFormat(`${summaryData?.resume.balanceToReceve}`)}
            </span>
          )}
        </h1>
      </div>
    </>
  );
}

export default Header;
